import { Model } from "../model";

export class Sidebar extends Model{
  public id: number;
  public icono: string;
  public codigo: string;
  public label: string;
  public titulo: string;
  public descripcion: string;
  public is_title: boolean;
  public is_layout: boolean;
  public is_visible: boolean;
  public is_active: boolean;
  public order: number;
  public badge?: any;
  public link?: string;
  public sub_items?: any[];
  public roles?: any[];

  constructor(data?: object){
    super(data);
    this.id = this.id || null;
    this.icono = this.icono || null;
    this.codigo = this.codigo || null;
    this.label = this.label || null;
    this.titulo = this.titulo || null;
    this.descripcion = this.descripcion || null;
    this.is_title = this.is_title || false;
    this.is_layout = this.is_layout || false;
    this.is_visible = this.is_visible || true;
    this.is_active = this.is_active || true;
    this.order = this.order || 0;
    this.badge = this.badge || null;
    this.link = this.link || null;
    this.sub_items = this.sub_items || [];
    this.roles = this.roles || [];
  }

  public static cast(data: object): Sidebar{
    const obj = new Sidebar(data);
    return {
      id: obj.id,
      icono: obj.icono,
      codigo: obj.codigo,
      label: obj.label,
      titulo: obj.titulo,
      descripcion: obj.descripcion,
      is_title: obj.is_title,
      is_layout: obj.is_layout,
      is_visible: obj.is_visible,
      is_active: obj.is_active,
      order: obj.order,
      badge: obj.badge,
      link: obj.link,
      sub_items: obj.sub_items,
      roles: obj.roles,
    }
  }

  public static casts(dataArray: object[]): Sidebar[]{
    return dataArray.map((data) => Sidebar.cast(data));
  }
}

export class SidebarList extends Model{
  public id: number;
  public icono: string;
  public codigo: string;
  public label: string;
  public titulo: string;
  public descripcion: string;
  public is_title: boolean;
  public is_layout: boolean;
  public is_visible: boolean;
  public is_active: boolean;
  public order: number;
  public badge?: any;
  public link?: string;
  public sub_items?: any[];
  public roles?: any[];

  constructor(data?: object){
    super(data);
    this.id = this.id || null;
    this.icono = this.icono || null;
    this.codigo = this.codigo || null;
    this.label = this.label || null;
    this.titulo = this.titulo || null;
    this.descripcion = this.descripcion || null;
    this.is_title = this.is_title || false;
    this.is_layout = this.is_layout || false;
    this.is_visible = this.is_visible || true;
    this.is_active = this.is_active || true;
    this.order = this.order || 1;
    this.badge = this.badge || null;
    this.link = this.link || null;
    this.sub_items = this.sub_items || [];
    this.roles = this.roles || [];
  }

  public static cast(data: object): SidebarList{
    const obj = new SidebarList(data);

   return {
      id: obj.id,
      icono: obj.icono,
      codigo: obj.codigo,
      label: obj.label,
      titulo: obj.titulo,
      descripcion: obj.descripcion,
      is_title: obj.is_title,
      is_layout: obj.is_layout,
      is_visible: obj.is_visible,
      is_active: obj.is_active,
      order: obj.order,
      badge: obj.badge,
      link: obj.link,
      sub_items: obj.sub_items,
      roles: obj.roles,
    }
  }

  public static casts(dataArray: object[]): SidebarList[]{
    return dataArray.map((data) => SidebarList.cast(data));
  }
}
