import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { environment } from '../environments/environment';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';


import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initFirebaseBackend } from './authUtils';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from './core/core.module';
import { JwtAuthInterceptor } from './core/helpers';

import { DataTablesModule } from 'angular-datatables'
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ExportAsModule } from 'ngx-export-as';
import { HttpProgressInterceptor } from './core/interceptors';
import { NgxEditorModule } from 'ngx-editor';


// if (environment.defaultauth === 'firebase') {
//   initFirebaseBackend(environment.firebaseConfig);
// } else {
//   // tslint:disable-next-line: no-unused-expression
//   FakeBackendInterceptor;
// }

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LayoutsModule,
    AppRoutingModule,

    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    ScrollToModule.forRoot(),
    ToastrModule.forRoot(),
    DataTablesModule,
    DragDropModule,
    ExportAsModule,
    NgxEditorModule.forRoot({
      locals: {
        // menú
        bold: 'Negrita',
        italic: 'Itálica',
        code: 'Código',
        underline: 'Subrayar',
        strike: 'Tachar',
        blockquote: 'Cita',
        bullet_list: 'Lista de viñeta',
        ordered_list: 'Lista ordenada',
        heading: 'Encabezado',
        h1: 'Encabezado 1',
        h2: 'Encabezado 2',
        h3: 'Encabezado 3',
        h4: 'Encabezado 4',
        h5: 'Encabezado 5',
        h6: 'Encabezado 6',
        align_left: 'Alinear a la izquierda',
        align_center: 'Alinear al centro',
        align_right: 'Alinear a la derecha',
        align_justify: 'Ajustar texto',
        text_color: 'Color de texto',
        background_color: 'Color de fondo',
        horizontal_rule: 'Regla horizontal',
        format_clear: 'Limpiar formato',
        insertLink: 'Insertar enlace',
        removeLink: 'Eliminar enlace',
        insertImage: 'Insertar imagen',
        indent: 'Aumentar sangría',
        outdent: 'Disminuir sangría',
        superscript: 'Superíndice',
        subscript: 'Subíndice',
        undo: 'Deshacer',
        redo: 'Rehacer',

        // popups, forms, others...
        url: 'URL',
        text: 'Texto',
        openInNewTab: 'Abrir en nueva pestaña',
        insert: 'Insertar',
        altText: 'Texto alternativo',
        title: 'Título',
        remove: 'Eliminar',
        enterValidUrl: 'Por favor ingrese una URL válida',
      }
    })

  ],
  bootstrap: [AppComponent,],
  providers: [
    // Authentication
    { provide: HTTP_INTERCEPTORS, useClass: JwtAuthInterceptor, multi: true },
    // Progreso de carga ENDPOINT,
    { provide: HTTP_INTERCEPTORS, useClass: HttpProgressInterceptor, multi: true },
    // LoaderService,
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
  ],
})
export class AppModule { }
