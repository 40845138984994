import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string | SafeHtml | undefined, search: string): SafeHtml {
    if (!value || !search) {
      return value || ''; // Si no hay valor o búsqueda, devuelve el texto tal cual
    }

    // Convertir SafeHtml a string si es necesario
    const rawValue = typeof value === 'string' ? value : this.safeHtmlToString(value);

    // Crear una expresión regular para buscar el texto
    const regex = new RegExp(`(${this.escapeRegex(search)})`, 'gi');

    // Reemplazar las coincidencias y envolverlas en una etiqueta con clase
    const highlightedValue = rawValue.replace(regex, `<span class="highlight">$1</span>`);

    // Retornar el HTML seguro
    return this.sanitizer.bypassSecurityTrustHtml(highlightedValue);
  }


  /**
   * Convierte un SafeHtml en string.
   */
  private safeHtmlToString(safeHtml: SafeHtml): string {
    // Crear un contenedor temporal para convertir SafeHtml a string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = safeHtml as string;
    return tempDiv.innerHTML;
  }

  /**
   * Escapa caracteres especiales en una cadena para usarlos en una expresión regular.
   */
  private escapeRegex(value: string): string {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
}
